























import {Component, Vue, Watch} from 'vue-property-decorator';
import {NavigationItemInterface} from '@/interfaces/NavigationItem.interface';
import {NavigationItemsStorage} from '@/misc/NavigationItemsStorage';
import Company from '@/models/Company';
import {namespace} from 'vuex-class';
import {Permission} from '@/misc/enums/permission.enum';

const CompanyStore = namespace('company');

@Component({})
export default class AdminNavigationBarComponent extends Vue {
  @CompanyStore.Action('loadActiveCompanyAction')
  private loadActiveCompanyAction!: (companyId: string) => Promise<Company>;
  @CompanyStore.Getter('activeCompany')
  private _company?: Company;
  @CompanyStore.Mutation('activeCompany')
  private activeCompany!: (company: Company) => void;

  private selectedNavItem = 'companyDashboard';
  private navigationItems: NavigationItemInterface[] = [];

  get company(): Company | undefined {
    return this._company ? this._company : undefined;
  }


  constructor() {
    super();
    this.setNavigationItems();
  }

  /**
   * Watch the route url for changing the navigation bar
   */
  @Watch('$route', {immediate: true, deep: true})
  private onUrlChange(routerValue: any) {
    // handle loading of activeCompany store value!

    this.handleActiveCompanyLoading();

    if (routerValue.name) {
      this.selectedNavItem = routerValue.name;
    }
    this.setNavigationItems();
  }

  /**
   *  Loads the active company according to the given parameter
   */
  private handleActiveCompanyLoading() {
    try {
      if (this.$route.params.companyId) { // The companyId in the url is prioritized
        this.loadActiveCompanyAction(this.$route.params.companyId);
      } else if (this.company && this.company.id) { // Else check the activeCompany in the vuex-store
        this.loadActiveCompanyAction(this.company.id);
      }
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.ACTIVE_COMPANY_ERROR');
    }
  }

  /**
   * Determines the navigation items regarding the current user role
   */
  private setNavigationItems() {
    this.navigationItems = NavigationItemsStorage
      .filter((item) => {
        if (item.routerName === 'locationsOverview') {
          return !this.$userRoleHandler.hasPermission(Permission.CUSTOMER_READ_OWN) && this.$userRoleHandler.hasPermission(item.permission);
        }
        return this.$userRoleHandler.hasPermission(item.permission);
      });
    // check if company id is available in url
    if (!this.$route.params.companyId) {
      this.navigationItems = this.navigationItems.filter((item) => !item.companyRequired);
    }
  }

  /**
   * On click handler that activates a route
   * @param item
   */
  public onNavItemClick(item: any) {
    this.selectedNavItem = item.routerName;
    this.$router.push({name: item.routerName, params: {companyId: this.$route.params.companyId}});
  }

}
