import {NavigationItemInterface} from '@/interfaces/NavigationItem.interface';
import i18n from '@/i18n';
import {Permission} from '@/misc/enums/permission.enum';

export const NavigationItemsStorage: NavigationItemInterface[] =
  [
    /*{
      icon: 'mdi-crown',
      title: i18n.t('NAVIGATION.TOOLTIPS.ADMIN_DASHBOARD').toString(),
      routerName: 'adminDashboard',
      permission: 'ADMIN_DASHBOARD',
      dropdownOptions: [],
      companyRequired: false,
      tooltip: 'ADMIN_DASHBOARD',
    },*/
    {
      icon: 'mdi-factory',
      title: i18n.t('NAVIGATION.TOOLTIPS.COMPANIES').toString(),
      routerName: 'companiesOverview',
      permission: Permission.COMPANY_READ,
      dropdownOptions: [],
      companyRequired: false,
      tooltip: 'COMPANIES',
      url: '/icons/home.svg',
    },
    /* TODO: hide until finished
      {
          icon: 'mdi-view-dashboard',
          title: i18n.t('GENERAL.DASHBOARD').toString(),
          routerName: 'companyDashboard',
          permission: 'COMPANY_DASHBOARD',
          dropdownOptions: [],
          companyRequired: true,
          tooltip: 'DASHBOARD',
      },
      */

    {
      icon: 'mdi-account',
      title: i18n.t('GENERAL.EMPLOYEES').toString(),
      routerName: 'usersOverview',
      permission: Permission.USER_READ_OWN,
      dropdownOptions: [],
      companyRequired: true,
      tooltip: 'EMPLOYEES',
      url: '/icons/person.svg',
    },
    {
      icon: 'mdi-briefcase',
      title: i18n.t('GENERAL.CUSTOMERS').toString(),
      routerName: 'customersOverview',
      permission: Permission.CUSTOMER_READ_OWN,
      dropdownOptions: [],
      companyRequired: true,
      tooltip: 'CUSTOMERS',
      url: '/icons/briefcase.svg',
    },
    {
      icon: 'mdi-briefcase',
      title: i18n.t('GENERAL.Locations').toString(),
      routerName: 'locationsOverview',
      permission: Permission.LOCATION_READ_OWN,
      dropdownOptions: [],
      companyRequired: true,
      tooltip: 'LOCATIONS',
      url: '/icons/briefcase.svg',
    },
    {
      icon: 'mdi-timer',
      title: i18n.t('GENERAL.TIME_TRACKING').toString(),
      routerName: 'timetrackingOverview',
      permission: Permission.WORKSESSION_READ_OWN,
      dropdownOptions: [],
      companyRequired: true,
      tooltip: 'TIME_TRACKING',
      url: '/icons/paper.svg',
    },
    {
      icon: 'mdi-calendar-month',
      title: i18n.t('GENERAL.JOBS').toString(),
      routerName: 'jobsOverview',
      permission: Permission.CLEANTIME_READ_OWN,
      dropdownOptions: [],
      companyRequired: true,
      tooltip: 'JOBS',
      url: '/icons/calendar.svg',
    },
    {
      icon: 'mdi-map-marker',
      title: i18n.t('GENERAL.MAP').toString(),
      routerName: 'mapOverview',
      permission: Permission.USER_READ_OWN,
      dropdownOptions: [],
      companyRequired: true,
      tooltip: 'MAP',
      url: '/icons/map.svg',
    },
    {
      icon: 'mdi-cog',
      title: i18n.t('NAVIGATION.TOOLTIPS.SETTINGS').toString(),
      routerName: 'settings',
      permission: Permission.COMPANY_READ_OWN,
      dropdownOptions: [],
      companyRequired: false,
      tooltip: 'SETTINGS',
      url: '/icons/cog.svg',
    },
  ];
